import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function HomeSection({data}) {
  const queryData = useStaticQuery(graphql`
    query HomeSectionQuery {
      markdownRemark(frontmatter: {section: {eq: "home"}}) {
        frontmatter {
          title
          image
          image_alt
        }
        html
      }
    }
  `)
  if (!data) {
    data = queryData
  }
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  const { title, image, image_alt } = frontmatter
  return (
    <section id="home" className="panel banner right">
      <div className="content color4 span-3-75">
        <h1 className="major">{title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <ul className="actions">
          <li key="nav_action"><a href="#skills" className="button primary color1 circle icon solid fa-angle-right">Next</a></li>
        </ul>
      </div>
      <div className="image filtered span-1-75" data-position="25% 25%">
        <img src={image} alt={image_alt} />
      </div>
    </section>
  );
}
