import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Socials() {
  const data = useStaticQuery(graphql`
    query SocialsMetadataQuery {
      site {
        siteMetadata {
          socials {
            facebook {
              url
              text
            }
            instagram {
              url
              text
            }
            linkedin {
              url
              text
            }
            github {
              url
              text
            }
            keybase {
              url
              text
            }
          }
          email
        }
      }
    }
  `)
  const { siteMetadata } = data.site
  return (
    <div className="span-1-5">
      <ul className="contact-icons color1">
        <li key="linkedin" className="icon brands fa-linkedin"><a href={siteMetadata.socials.linkedin.url}>{siteMetadata.socials.linkedin.text}</a></li>
        <li key="github" className="icon brands fa-github"><a href={siteMetadata.socials.github.url}>{siteMetadata.socials.github.text}</a></li>
        <li key="keybase" className="icon brands fa-keybase"><a href={siteMetadata.socials.keybase.url}>{siteMetadata.socials.keybase.text}</a></li>
        <li key="facebook" className="icon brands fa-facebook-f"><a href={siteMetadata.socials.facebook.url}>{siteMetadata.socials.facebook.text}</a></li>
        <li key="instagram" className="icon brands fa-instagram"><a href={siteMetadata.socials.instagram.url}>{siteMetadata.socials.instagram.text}</a></li>
      </ul>
    </div>
  );
}
