import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function SkillsSection({data}) {
  const queryData = useStaticQuery(graphql`
    query SkillsSectionQuery {
      markdownRemark(frontmatter: {section: {eq: "skills"}}) {
        frontmatter {
          title
        }
        html
      }
    }
  `)
  if (!data) {
    data = queryData
  }
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  const { title } = frontmatter
  return (
    <section className="panel color0" id="skills">
      <div className="intro joined">
        <h2 className="major">{title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <ul className="actions">
          <li key="nav_action"><a href="#interests" className="button primary color1 circle icon solid fa-angle-right">Next</a></li>
        </ul>
      </div>
      <div className="inner">
        <ul className="grid-icons span-3">
          <li key="Infrastructure"><span className="icon solid fa-cloud">Infrastructure</span></li>
          <li key="Security"><span className="icon solid fa-lock">Security</span></li>
          <li key="Networking"><span className="icon solid fa-network-wired">Networking</span></li>
          <li key="Kubernetes"><span className="icon solid fa-dharmachakra">Kubernetes</span></li>
          <li key="Git"><span className="icon solid fa-code-branch">Git</span></li>
          <li key="Development"><span className="icon solid fa-code">Development</span></li>
          <li key="Microservices"><span className="icon solid fa-vector-square">Microservices</span></li>
          <li key="Linux"><span className="icon solid fa-terminal">Linux</span></li>
          <li key="DataOps"><span className="icon solid fa-database">DataOps</span></li>
          <li key="DevOps"><span className="icon solid fa-infinity">DevOps</span></li>
          <li key="Serverless"><span className="icon solid fa-dice-d20">Serverless</span></li>
          <li key="Hardware"><span className="icon solid fa-desktop">Hardware</span></li>
        </ul>
      </div>
    </section>
  );
}
