import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Socials from "../components/Socials"

export default function ContactSection({data}) {
  const queryData = useStaticQuery(graphql`
    query ContactQuery {
      markdownRemark(frontmatter: {section: {eq: "contact"}}) {
        html
        frontmatter {
          title
        }
      }
    }
  `)
  if (!data) {
    data = queryData
  }

  const { html, frontmatter } = data.markdownRemark
  return (
    <section className="panel color4-alt" id="contact">
      <div className="intro color4">
        <h2 className="major">{frontmatter.title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div className="inner columns divided">
        <div className="span-3-25">
          <form name="contact" method="post" data-netlify="true" netlify-honeypot="bot-field">
            <input type="hidden" aria-label="bot-field" name="bot-field" />
            <input type="hidden" aria-label="form-name" name="form-name" value="contact" />
            <div className="fields">
              <div className="field half">
                <label htmlFor="name">Name</label>
                <input aria-label="text" type="text" name="name" id="name" />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input aria-label="email" type="email" name="email" id="email" />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea aria-label="message" name="message" id="message" rows="4"></textarea>
              </div>
            </div>
            <ul className="actions">
              <li key="submit"><input aria-label="submit" type="submit" value="Send Message" className="button primary" /></li>
            </ul>
          </form>
        </div>
        <Socials/>
      </div>
    </section>
  );
}
